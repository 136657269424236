import React from "react";
import {
	ComposableMap,
	Geographies,
	Geography,
	ZoomableGroup,
	Marker,
	Line,
} from "react-simple-maps";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const MapChart = (props) => (
	<ComposableMap style={{ border: `#DDD Solid` }} projection="geoAlbers">
		<ZoomableGroup zoom={props.zoom} center={props.center}>
			<Geographies geography={geoUrl}>
				{({ geographies }) =>
					geographies.map((geo) => (
						<Geography
							key={geo.rsmKey}
							stroke="#FFF"
							geography={geo}
							fill="#DDD"
						/>
					))
				}
			</Geographies>

			{props.lines.map(({ from, to }) => (
				<Line from={from} to={to} stroke="#5D5A6D" strokeWidth={0.75} />
			))}

			{props.markers.map(
				({ name, coordinates, offY = 1.75, offX = 0, color = "#000" }) => (
					<Marker key={name} coordinates={coordinates}>
						<circle r={2.5} fill="#58b" stroke="#58b" />
						<text
							textAnchor="middle"
							y={offY}
							x={offX}
							style={{ fontSize: 7, fill: "#5D5A6D" }}
						>
							{name}
						</text>
					</Marker>
				)
			)}
		</ZoomableGroup>
	</ComposableMap>
);

export default MapChart;
