import React from "react";
import MapChart from "../../components/mapchart";
import TableRow from "../../components/tableRow";

import "../../styles/w3.css";
import "../../styles/react-tabs.css";

const markers = [
	{
		offY: -5,
		name: "Feb 17",
		coordinates: [-93, 37],
	},
	{
		offY: -5,
		name: "Mar 3",
		coordinates: [-96, 36.7],
	},
	{
		offY: 8,
		name: "Apr 14",
		coordinates: [-93, 35.5],
	},
	{
		offX: 14,
		name: "May 12",
		coordinates: [-93, 36.2],
	},
	{
		offY: -5,
		name: "6/26-7/7",
		coordinates: [-94, 36.2],
	},
	{
		offX: -13,
		name: "Aug 4",
		coordinates: [-95, 32.7],
	},
];

const lines = [
	{
		from: [-93, 37],
		to: [-96, 36.7],
	},
	{
		from: [-96, 36.7],
		to: [-93, 35.5],
	},
	{
		from: [-93, 35.5],
		to: [-93, 36.2],
	},
	{
		from: [-93, 36.2],
		to: [-94, 36.2],
	},
	{
		from: [-94, 36.2],
		to: [-95, 32.7],
	},
];

const Tour2018 = () => (
	<div className={"map w3-row-padding w3-mobile"}>
		<div className={"w3-col s4 w3-mobile mobile-only"}>
			<MapChart markers={markers} lines={lines} zoom={6} center={[-95, 35]} />
		</div>
		<div className={"w3-col s8 w3-mobile"}>
			<table className={"w3-table-all w3-text-black"}>
				<thead>
					<tr>
						<th>Dates</th>
						<th>Tournament</th>
						<th>Tier</th>
						<th>Location</th>
					</tr>
				</thead>
				<tbody>
					<TableRow
						d={"Feb 17"}
						to={"Presidential Cup III presented by Gateway"}
						ti={"C"}
						l={"Springfield, MO"}
					/>
					<TableRow
						d={"Mar 3"}
						to={"Shawnee Trails"}
						ti={"C"}
						l={"Bartlesville, OK"}
					/>
					<TableRow
						d={"Apr 14"}
						to={"Vintage Open - Amateur"}
						ti={"B"}
						l={"Russellville, AR"}
					/>
					<TableRow
						d={"May 12"}
						to={"The 6th Annual North Ark Open"}
						ti={"B"}
						l={"Harrison, AR"}
					/>
					<TableRow
						d={"May 26"}
						to={"Dynamic Discs Presents: 2018 Twin Creeks Classic"}
						ti={"C"}
						l={"Rogers, AR"}
					/>
					<TableRow
						d={"May 27"}
						to={"2018 NGT Qualifier - R2: JB Hunt DGC"}
						ti={"C"}
						l={"Springdale, AR"}
					/>
					<TableRow
						d={"Jul 7"}
						to={"9th Annual Monkey Trap Open - Driven By Innova"}
						ti={"B"}
						l={"Springdale, AR"}
					/>
					<TableRow
						d={"Aug 4"}
						to={"Guthrie Creek Warm Up VII"}
						ti={"C"}
						l={"Longview, TX"}
					/>
				</tbody>
			</table>
			<br />
			<br />
			<br />
		</div>
		<div className={"w3-col s4 w3-mobile desktop-only"}>
			<MapChart markers={markers} lines={lines} zoom={6} center={[-95, 35]} />
		</div>
	</div>
);

export default Tour2018;
