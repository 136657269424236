import React from "react";
import MapChart from "../../components/mapchart";
import TableRow from "../../components/tableRow";

import "../../styles/w3.css";
import "../../styles/react-tabs.css";

const markers = [
	{
		offY: -5,
		name: "Feb 16",
		coordinates: [-93, 37],
	},
	{
		offX: 13,
		name: "Mar 9",
		coordinates: [-91, 36],
	},
	{
		offY: 10,
		name: "Mar 16",
		coordinates: [-95, 32.7],
	},
	{
		offY: -5,
		name: "Mar 27-30",
		coordinates: [-82.1, 33.2],
	},
	{
		offY: -5,
		offX: 2,
		name: "6/22-7/20",
		coordinates: [-97.2, 32.9],
	},
	{
		offY: 17,
		name: "Aug 3",
		coordinates: [-95, 32.7],
	},
	{
		offX: -16,
		offY: 9,
		name: "8/24-9/29",
		coordinates: [-96, 32.5],
	},
	{
		offY: 8,
		name: "Nov 16-17",
		coordinates: [-93, 32.8],
	},
	{
		offY: 0,
		offX: 14,
		name: "Nov 23",
		coordinates: [-94, 33.3],
	},
];

const lines = [
	{
		from: [-93, 37],
		to: [-91, 36],
	},
	{
		from: [-91, 36],
		to: [-95, 32.7],
	},
	{
		from: [-95, 32.7],
		to: [-82.1, 33.2],
	},
	{
		from: [-82.1, 33.2],
		to: [-97.2, 32.9],
	},
	{
		from: [-97.2, 32.9],
		to: [-95, 32.7],
	},
	{
		from: [-95, 32.7],
		to: [-96, 32.5],
	},
	{
		from: [-96, 32.5],
		to: [-93, 32.8],
	},
	{
		from: [-93, 32.8],
		to: [-94, 33.3],
	},
];

const Tour2019 = () => (
	<div className={"map w3-row-padding w3-mobile"}>
		<div className={"w3-col s4 w3-mobile mobile-only"}>
			<MapChart
				markers={markers}
				lines={lines}
				zoom={3}
				center={[-89.5, 35]}
			></MapChart>
		</div>
		<div className={"w3-col s8 w3-mobile"}>
			<table className={"w3-table-all w3-text-black"}>
				<thead>
					<tr>
						<th>Dates</th>
						<th>Tournament</th>
						<th>Tier</th>
						<th>Location</th>
					</tr>
				</thead>
				<tbody>
					<TableRow
						d={"Feb 16"}
						to={"Presidential Cup IV sponsored by Dynamic Discs"}
						ti={"B"}
						l={"Springfield, MO"}
					/>
					<TableRow
						d={"Mar 9"}
						to={"MidSouth Collegiate Open - Individual"}
						ti={"XC"}
						l={"Jonesboro, AR"}
					/>
					<TableRow
						d={"Mar 16"}
						to={"LDGA's Bruce Cabbiness Memorial - Sponsored by Dynamic Discs"}
						ti={"C"}
						l={"Longview, TX"}
					/>
					<TableRow
						d={"Mar 27-30"}
						to={"National Collegiate First Flight Championship - Individual"}
						ti={"XM"}
						l={"Appling, GA"}
					/>
					<TableRow
						d={"Jun 22"}
						to={
							"17th Fahrenheit Fling presented by Texas Chainwreck and Arlington Disc Golf Assoc."
						}
						ti={"B"}
						l={"Arlington, TX"}
					/>
					<TableRow
						d={"Jul 20"}
						to={"Harry Myers 2.0 presented by Slow and Steady DG"}
						ti={"C"}
						l={"Rockwall, TX"}
					/>
					<TableRow
						d={"Aug 3"}
						to={"GCWU: 'The Ocho' - Presented by LDGA + Driven by Innova"}
						ti={"C"}
						l={"Longview, TX"}
					/>
					<TableRow
						d={"Aug 24-25"}
						to={
							"Lindsey Park Championship presented by Slow and Steady DG sponsored by INNOVA"
						}
						ti={"C"}
						l={"Tyler, TX"}
					/>
					<TableRow
						d={"Sep 27-29"}
						to={
							"Dynamic Discs and the Rose City Disc Club Present the Piney Woods Open"
						}
						ti={"A"}
						l={"Tyler, TX"}
					/>
					<TableRow
						d={"Sep 28"}
						to={"Piney Woods Open Flex Start @ The ROC Presented by Tree Love"}
						ti={"C"}
						l={"Tyler, TX"}
					/>
					<TableRow
						d={"Nov 16-17"}
						to={"Dynamic Discs Presents: The Lake Claiborne Championships"}
						ti={"B"}
						l={"Homer, LA"}
					/>
					<TableRow
						d={"Nov 23"}
						to={"3rd Annual Ashley Lane Bell Memorial"}
						ti={"B"}
						l={"Texarkana, TX"}
					/>
				</tbody>
			</table>
			<br />
			<br />
			<br />
		</div>
		<div className={"w3-col s4 w3-mobile desktop-only"}>
			<MapChart markers={markers} lines={lines} zoom={3} center={[-89.5, 35]} />
		</div>
	</div>
);

export default Tour2019;
