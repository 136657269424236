import React from "react";
import "../styles/w3.css";

const TableRow = (props) => (
	<tr>
		<td>{props.d}</td>
		<td>{props.to}</td>
		<td>{props.ti}</td>
		<td>{props.l}</td>
	</tr>
);

export default TableRow;
