import React from "react";
import Layout from "../components/layout";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Tour2018 from "../components/tour/tour2018";
import Tour2019 from "../components/tour/tour2019";
import Tour2020 from "../components/tour/tour2020";

import "../styles/w3.css";
import "../styles/react-tabs.css";

const TourPage = () => (
	<Layout title="Tour">
		<div
			className={
				"content tour w3-padding-large w3-small w3-mobile mobile-width"
			}
		>
			<h1 className={"w3-xlarge w3-animate-top w3-center"}>
				PDGA Tournament Schedule
			</h1>
			<Tabs>
				<TabList>
					<Tab>2020</Tab>
					<Tab>2019</Tab>
					<Tab>2018</Tab>
				</TabList>
				<TabPanel>
					<Tour2020 />
				</TabPanel>
				<TabPanel>
					<Tour2019 />
				</TabPanel>
				<TabPanel>
					<Tour2018 />
				</TabPanel>
			</Tabs>
			<br />
			<br />
			<br />
			<br />
			<br />
		</div>
	</Layout>
);

export default TourPage;
