import React from "react";
import MapChart from "../../components/mapchart";
import TableRow from "../../components/tableRow";

import "../../styles/w3.css";
import "../../styles/react-tabs.css";

const markers = [
	{
		offY: 5,
		offX: -12,
		name: "Jan 1",
		coordinates: [-95.1, 32.2],
		color: "#F00",
	},
	{
		offX: 10,
		offY: 9,
		name: "Feb 23",
		coordinates: [-93.7, 32.75],
		color: "#F80",
	},
	{
		offY: -13,
		offX: -8,
		name: "Mar 7",
		coordinates: [-95, 32.7],
		color: "#0A0",
	},
	{
		offX: 14,
		name: "Jul 25",
		coordinates: [-93, 36.2],
	},
	{
		offY: -5,
		offX: -8,
		name: "Aug 1",
		coordinates: [-95, 32.7],
		color: "#0A0",
	},
	{
		offY: 9,
		name: "Dec 12",
		coordinates: [-94.6, 31.6],
		color: "#00A",
	},
];

const lines = [
	{
		from: [-95.1, 32.2],
		to: [-93.7, 32.75],
	},
	{
		from: [-93.7, 32.75],
		to: [-95, 32.7],
	},
	{
		from: [-95, 32.7],
		to: [-93, 36.2],
	},
	{
		from: [-95, 32.7],
		to: [-94.6, 31.6],
	},
];

const Tour2020 = () => (
	<div className={"map w3-row-padding w3-mobile"}>
		<div className={"w3-col s4 w3-mobile mobile-only"}>
			<MapChart markers={markers} lines={lines} zoom={5} center={[-94, 34]} />
		</div>
		<div className={"w3-col s8 w3-mobile"}>
			<table className={"w3-table-all w3-text-black"}>
				<thead>
					<tr>
						<th>Dates</th>
						<th>Tournament</th>
						<th>Tier</th>
						<th>Location</th>
					</tr>
				</thead>
				<tbody>
					<TableRow
						d={"Jan 1"}
						to={"New Years Resolution 2020"}
						ti={"C"}
						l={"Henderson, TX"}
					/>
					<TableRow
						d={"Feb 23"}
						to={"Dynamic Discs Presents: 7th Annual Port City Open - Pro"}
						ti={"C"}
						l={"Shreveport, LA"}
					/>
					<TableRow
						d={"Mar 7"}
						to={"Bruce Cabbiness Memorial II sponsored by Dynamic Discs"}
						ti={"B"}
						l={"Longview, TX"}
					/>
					<TableRow
						d={"Jul 25"}
						to={"Elephant & Castle presents: The 8th Annual North Ark Open"}
						ti={"B"}
						l={"Harrison, AR"}
					/>
					<TableRow
						d={"Aug 1"}
						to={
							"Guthrie Creek Warm Up IX - Presented by LDGA + Driven by Innova"
						}
						ti={"B"}
						l={"Longview, TX"}
					/>
					<TableRow
						d={"Dec 12"}
						to={"SoSo Classic"}
						ti={"C"}
						l={"Timpson, TX"}
					/>
				</tbody>
			</table>
			<br />
			<br />
			<br />
		</div>
		<div className={"w3-col s4 w3-mobile desktop-only"}>
			<MapChart markers={markers} lines={lines} zoom={5} center={[-94, 34]} />
		</div>
	</div>
);

export default Tour2020;
